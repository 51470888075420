import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Valkommen from "../components/index/Valkommen"
import Meny from "../components/restaurang/Meny"
import { GasMeny } from "../pages/gas"
import Jul from "../components/jul/Jul"
import Pask from "../components/pask/Pask"

function IndexPage(props) {
  const data = useStaticQuery(graphql`
    {
      allWpRatt {
        nodes {
          id
          informationMat {
            beskrivning
            pris
          }
          title
        }
      }
    }
  `)
  const mat = data.allWpRatt.nodes
  return (
    <Layout>
      <SEO
        keywords={[
          `Köttbutik`,
          `Köttdisk`,
          `Julbord`,
          `Julmat`,
          `Wagyu`,
          `Lunch`,
        ]}
        title="Clemens Kött & Husman"
        path={props.path}
      />
      <section className="bg-cherry bg-opacity-10" id="om-oss">
        <Valkommen />
      </section>

      <section id="pask" className="max-w-xl mx-auto px-2 md:px-8 py-8 ">
        <GasMeny />
      </section>
      <section className="max-w-5xl mx-auto px-4 md:px-8">
        <Meny mat={mat} />
      </section>
      {/* <section id="gas" className="max-w-xl mx-auto px-8  pb-8 ">
        <Jul />
      </section> */}
    </Layout>
  )
}

export default IndexPage
